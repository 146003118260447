<template>

  <div>

    <b-card>

      <h2 class="mb-2">
        Search Filter
      </h2>

      <!-- Table Top -->
      <b-row>

        <b-col
          cols="12"
          md="4"
          class="w-100"
        >
          <v-select
            v-model="filterDataProgram"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterProgramsOptions"
            :clearable="false"
            :placeholder="'Select Program'"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="w-100"
        >
          <v-select
            v-model="filterDataGroup"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterGroupOptions"
            :clearable="false"
            :placeholder="'Select Group'"
          />
        </b-col>

      </b-row>

    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="table-header m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mr-1 mb-0">Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              :reduce="option => option.value"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <label class="mr-1 mb-0">Search</label>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                class="w-25"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="fetchCampersList"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Column: camper name -->
        <template #cell(name)="data">
          <b-avatar
            class="mr-1"
            size="32"
            :src="data.item.user.avatar"
          />
          {{ data.item.user.full_name }}
        </template>

        <!-- Column: gender -->
        <template #cell(gender)="data">
          {{ data.item.user.extra_user_info.gender }}
        </template>

        <!-- Column: status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveStatusVariant(data.value)}`"
          >
            {{ data.value }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="mx-auto p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-for="option in actionOptions"
              :key="option.value"
              :to="{ name: option.link, params: { id: data.item.id } }"
            >
              <span>{{ option.title }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="totalItems && perPage !== 'all'"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>

  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination,
  BDropdown, BDropdownItem, BAvatar, BBadge,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import store from '@/store'
import storeModule from '@/views/staff/campers/campersStoreModule'
import useCampersList from '@/views/staff/campers/campers-list/useCampersList'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BBadge,

    vSelect,
  },
  data() {
    return {
      filterProgramsOptions: [],
      filterGroupOptions: [],
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-campers'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchCampersList,
      tableColumns,
      perPage,
      perPageOptions,
      currentPage,
      totalItems,
      searchQuery,
      filterDataProgram,
      filterDataGroup,
      sortBy,
      dataMeta,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,
      actionOptions,

      refetchData,

      resolveStatusVariant,
    } = useCampersList()

    return {
      fetchCampersList,
      tableColumns,
      perPage,
      perPageOptions,
      currentPage,
      totalItems,
      sortBy,
      searchQuery,
      filterDataProgram,
      filterDataGroup,
      dataMeta,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,
      actionOptions,

      refetchData,

      resolveStatusVariant,
    }
  },
  async created() {
    const userData = getUserData()
    const formData = {
      userId: userData?.id,
    }
    this.filterProgramsOptions = await this.$store.dispatch('app-campers/fetchProgramList', formData)
      .then(response => response.data.data.reduce((arr, item) => {
        arr.push({ id: item.id, label: item.name })
        return arr
      }, []))

    this.filterGroupOptions = await this.$store.dispatch('app-campers/fetchGroupList', formData)
      .then(response => response.data.data.reduce((arr, item) => {
        arr.push({ id: item.id, label: item.name })
        return arr
      }, []))
  },
}
</script>

<style lang="scss" scoped>
  .table-header {

    label {
      margin-bottom: 0;
    }
  }

  .badge {
    text-transform: uppercase;
  }

  .per-page-selector {
    width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
